<template>
    <div class="content-header banner">
      <div class="gradient"></div>
      <div class="row client">
        <div class="col-md-12">
          <div class="text-center">
            <h2 v-if="title" class="text-white">
              {{ title.toUpperCase().substring(0, 20) }}
            </h2>
            <h3 v-if="subtitle" class="text-white">
              <strong> {{ subtitle.toUpperCase().substring(0, 20) }} </strong>
            </h3>
            <!-- <p v-if="description" class="text-white client"> {{description}}</p> -->
            <p v-if="description" class="text-white m-1">
              {{ description.substring(0, 300) }}
            </p>
  
            <span v-for="(brdcrmb, index) in breadcrumb" :key="index">
              <router-link class="text-white" v-if="brdcrmb.url" :to="brdcrmb.url"
                ><b>{{ brdcrmb.label }}</b></router-link
              >
              <strong class="text-white" v-else>{{ brdcrmb.label }}</strong>
              <span class="text-white" v-if="breadcrumb.length > index + 1"> > </span>
            </span>
            <!-- <nav class="nav text-center" aria-label="breadcrumb"> -->
            <!-- <ol class="breadcrumb">
                              <li class="breadcrumb-item text-white" aria-current="page" v-for="(brdcrmb, index) in breadcrumb" :key="index">
                                  <router-link class="text-white" v-if="brdcrmb.url" :to="brdcrmb.url"><b>{{brdcrmb.label}}</b></router-link>
                                  <b v-else>{{brdcrmb.label}}</b>
                              </li>
                          </ol> -->
            <!-- </nav> -->
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: ["title", "subtitle", "description", "breadcrumb"],
  };
  </script>
  
  <style scoped>
  h2 {
    color: #fff;
    font-family: poppins, Sans-serif;
    font-size: 40px;
    font-weight: 600;
  }
  p {
    color: #fff;
    font-family: poppins, Sans-serif;
    font-size: 15px;
    font-weight: 500;
  }
  .nav {
    /* --bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='currentColor'/%3E%3C/svg%3E&#34;); */
    position: absolute;
    /* left: 40%;
    top: 190px;
    font-size: 50pt; */
    font-family: "Poppins", sans-serif;
    /* transform: matrix(-40%); */
    color: white;
  }
  
  .client {
    width: 100%;
    /* font-size: 25pt; */
    position: absolute;
    top: 100px;
    /* left: 42%; */
    font-family: "Poppins", sans-serif;
  }
  
  .banner {
    background-image: url("/assets/image/bannerimage.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 200px;
    font-family: "Poppins", sans-serif;
  }
 
  </style>
  