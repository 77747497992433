<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="PROJECT VIEW"
          :breadcrumb="[
            {
              label: 'Project View',
              url: 'http://localhost:8080/retailer/client',
            },
            { label: 'Project' },
          ]"
        >
        </banner>

        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-6 col-md-6 col-sm-6">
            <div
              class="card radius"
              style="
                height: 100%;
                box-shadow: 0px 0px 10px gray;
                margin-right: 5px;
                margin-bottom: 0px;
                height: 100%;
              "
            >
              <div class="card-body pt-2">
                <div class="row">
                  <div class="col">
                    <h4 class="d-xl-none d-md-none d-block text-center">
                      <b style="color: #00364f; font-weight: bolder"
                        >Client All Information</b
                      >
                    </h4>
                    <h4 class="d-none d-xl-block d-md-block text-start">
                      <b style="color: #00364f; font-weight: bolder"
                        >Client All Information</b
                      >
                    </h4>
                  </div>
                </div>

                <div class="row">
                  <!-- img responsive mobile  -->
                  <div
                    class="col-sm-12 col-12 d-block d-md-none d-xl-none d-lg-none text-center mb-1"
                  >
                    <img
                      style="width: 40%; border-radius: 15px"
                      :src="profileImg"
                      alt=""
                    />
                    <br />
                    <span style="color: #00364f">
                      <p
                        v-if="clientService.status"
                        class="text-white btn btn-sm"
                        style="
                          background-color: green;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> Active</b>
                      </p>
                      <p
                        v-else
                        class="text-white btn btn-sm"
                        style="
                          background-color: red;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> InActive</b>
                      </p>
                    </span>
                  </div>
                  <h5 class="text-start">
                    <b style="color: #f21300; font-weight: bolder">Personal Details </b>
                  </h5>

                  <div class="col-xl-9 col-md-9 col-lg-9 col-sm-12">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Code : </b>
                      <span v-if="clientService.client">
                        {{ clientService.client.code }}
                      </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Name : </b>
                      <span v-if="clientService.client">
                        {{ clientService.client.fname }}
                        {{ clientService.client.lname }}</span
                      >
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>DOB : </b>
                      <span v-if="clientService.client">
                        {{ clientService.client.dob }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Gender : </b>
                      <span v-if="clientService.client">
                        {{ clientService.client.gender }}
                      </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Phone Number : </b>
                      <span v-if="clientService.client">
                        {{ clientService.client.phone }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Address : </b>
                      <span v-if="clientService.client">
                        {{ clientService.client.address }}</span
                      >
                    </div>
                  </div>
                  <!-- img responsive Pc  -->
                  <div
                    class="col-xl-3 col-md-3 col-lg-3 d-none d-xl-block d-md-block d-lg-block text-center"
                  >
                    <img
                      style="width: 100%; border-radius: 15px; margin-top: -20px"
                      :src="profileImg"
                      alt=""
                    />
                    <span style="color: #00364f; width: 40%">
                      <p
                        v-if="clientService.status"
                        class="text-white btn btn-sm"
                        style="
                          background-color: green;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> Active</b>
                      </p>
                      <p
                        v-else
                        class="text-white btn btn-sm"
                        style="
                          background-color: red;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> InActive</b>
                      </p>
                    </span>
                  </div>
                </div>

                <div class="row">
                  <h5 class="text-start mt-2">
                    <b style="color: #f21300; font-weight: bolder">Admin </b>
                  </h5>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Admin Code : </b>
                      <span v-if="clientService.admin">
                        {{ clientService.admin.code }}</span
                      >
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Admin Name : </b>
                      <span v-if="clientService.admin">
                        {{ clientService.admin.name }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Admin Commission : </b>
                      <span v-if="clientService">
                        {{ clientService.admin_commission }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Admin Email : </b>
                      <span v-if="clientService.admin">
                        {{ clientService.admin.email }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Admin Phone : </b>
                      <span v-if="clientService.admin">
                        {{ clientService.admin.phone }}</span
                      >
                    </div>
                    <h5 class="text-start mt-2">
                      <b style="color: #f21300; font-weight: bolder">Rtailer </b>
                    </h5>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />

                      <b>Retailer Code : </b>
                      <span v-if="clientService.retailer">
                        {{ clientService.retailer.code }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Retailer Name : </b>
                      <span v-if="clientService.retailer">
                        {{ clientService.retailer.name }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Retailer Commission : </b>
                      <span v-if="clientService">
                        {{ clientService.retailer_commission }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Retailer Email : </b>
                      <span v-if="clientService.retailer">
                        {{ clientService.retailer.email }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Retailer Phone : </b>
                      <span v-if="clientService.retailer">
                        {{ clientService.retailer.phone }}</span
                      >
                    </div>
                    <h5 class="text-start mt-2">
                      <b style="color: #f21300; font-weight: bolder">Professional </b>
                    </h5>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Professional Code : </b>
                      <span v-if="clientService.professional">
                        {{ clientService.professional.code }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Professional Name : </b>
                      <span v-if="clientService.professional">
                        {{ clientService.professional.name }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Professional Fees : </b>
                      <span v-if="clientService">
                        {{ clientService.professionalFee }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Professional Email : </b>
                      <span v-if="clientService.professional">
                        {{ clientService.professional.email }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Professional Phone : </b>
                      <span v-if="clientService.professional">
                        {{ clientService.professional.phone }}</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="row mt-1"
                  style="background-color: #e3e3e3; border-radius: 15px"
                >
                  <div class="col-12 p-1">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>IP Address :</b>
                      <span v-if="clientService.client">{{
                        clientService.client.ipAddress
                      }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Last Login :</b>
                      <span v-if="clientService.client">{{
                        clientService.client.last_login
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-md-6 col-sm-6">
            <div
              class="card radius"
              style="box-shadow: 0px 0px 10px gray; margin-left: 3px; height: 100%"
            >
              <div class="card-body">
                <!-- bussiness Detais  -->
                <div class="row">
                  <h5 class="text-start mt-2">
                    <b style="color: #f21300; font-weight: bolder">Distributer </b>
                  </h5>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Distributer Code : </b>
                      <span v-if="clientService.distributer">
                        {{ clientService.distributer.code }}</span
                      >
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Distributer Name : </b>
                      <span v-if="clientService.distributer">
                        {{ clientService.distributer.name }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Distributer Commission : </b>
                      <span v-if="clientService">
                        {{ clientService.distributer_commission }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Distributer Email : </b>
                      <span v-if="clientService.distributer">
                        {{ clientService.distributer.email }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Distributer Email : </b>
                      <span v-if="clientService.distributer">
                        {{ clientService.distributer.phone }}</span
                      >
                    </div>
                    <h5 class="text-start mt-2">
                      <b style="color: #f21300; font-weight: bolder"
                        >Master Distributer
                      </b>
                    </h5>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />

                      <b>Master Distributer Code : </b>
                      <span v-if="clientService.master_distributer">
                        {{ clientService.master_distributer.code }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Master Distributer Name : </b>
                      <span v-if="clientService.master_distributer">
                        {{ clientService.master_distributer.name }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Master Distributer Commission : </b>
                      <span v-if="clientService">
                        {{ clientService.master_distributer_commission }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Master Distributer Email : </b>
                      <span v-if="clientService.master_distributer">
                        {{ clientService.master_distributer.email }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Master Distributer Email : </b>
                      <span v-if="clientService.master_distributer">
                        {{ clientService.master_distributer.phone }}</span
                      >
                    </div>
                    <h5 class="text-start mt-2">
                      <b style="color: #f21300; font-weight: bolder">staff </b>
                    </h5>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Staff Code : </b>
                      <span v-if="clientService.staff">
                        {{ clientService.staff.code }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Staff Name : </b>
                      <span v-if="clientService.staff">
                        {{ clientService.staff.name }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Staff Email : </b>
                      <span v-if="clientService.staff">
                        {{ clientService.staff.email }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Staff Phone : </b>
                      <span v-if="clientService.staff">
                        {{ clientService.staff.phone }}</span
                      >
                    </div>

                    <h5 class="text-start mt-2">
                      <b style="color: #f21300; font-weight: bolder">Service Modules </b>
                    </h5>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Module Name : </b>
                      <span v-if="clientService.serviceModules">
                        {{ clientService.serviceModules.moduleName }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Remark : </b>
                      <span v-if="clientService.serviceModules">
                        {{ clientService.serviceModules.remark }}</span
                      >
                    </div>
                    <h5 class="text-start mt-2">
                      <b style="color: #f21300; font-weight: bolder">Service </b>
                    </h5>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />

                      <b>Service Code : </b>
                      <span v-if="clientService.service">
                        {{ clientService.service.code }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Service Name : </b>
                      <span v-if="clientService.service">
                        {{ clientService.service.name }}</span
                      >
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Government Fees : </b>
                      <span v-if="clientService.service">
                        {{ clientService.service.governmentFee }}</span
                      >
                    </div>
                  </div>
                </div>
                <!-- end  bussiness Detais  -->
              </div>

              <div class="row p-2">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12 text-end">
                  <router-link to="/admin/projects">
                    <button
                      type="button"
                      class="btn text-white btn-sm"
                      style="background-color: #f21300"
                    >
                      <b>Go Back</b>
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>
</template>

//
<script>
import Banner from "../../../components/retailer/comman/Banner.vue";
// import Form from "vform";
// import jQuery from "jquery";
// let $ = jQuery
export default {
  name: "All Projects",

  components: {
    Banner,
  },
  data() {
    return {
      clientService: {},
      profileImg: this.$store.state.placeholderImg,
    };
  },
  methods: {
    loadClientServices() {
      this.$axios
        .get(`admin/clientservice/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data);
          this.clientService = res.data.data;
        });
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadClientServices();
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  width: 95%;
  background-color: white;
  border-radius: 20px;
  box-shadow: 2px 2px 8px gray;
  line-height: 10%;
  margin-left: 13px;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

th {
  width: 200px;
}

.table-scroll {
  overflow-y: auto;
}
</style>
